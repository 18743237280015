import { useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import { AdPlacement, ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { Experiment, RunningExperiments } from '@web/molecules/Experiment';
import { HeadlinesDossier } from '@web/molecules/HeadlinesDossier';
import { DisplayType, Injection, NewsList } from '@web/molecules/NewsList';
import { MatchBlock } from '@web/organisms/MatchBlock';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';
import { NewsListInsert } from '@web/templates/HomePage/organisms';

import { shouldInclude } from '../utils';
import { Props } from './HomeNewsList';
import { DisplayConfig } from './utils';

const OddsDossierIntro = dynamic(() =>
    import('@web/molecules/OddsDossierIntro').then((module) => module.OddsDossierIntro),
);

type InjectionType = {
    injections: Injection[];
};

/**
 * Create a ref array of <Ad/> and other injections for use in the NewsList.
 *
 * @example
 * injectedItems[increment.current] = <HeadlinesDossier
 *     dossier={headlinesDossier}
 *     />;
 *
 * @info `useRef` is used to prevent re-rendering when increment is updated
 * */
export const useInjections = (
    platform: PlatformID,
    props: Partial<Props>,
    displayConfig: DisplayConfig,
): InjectionType => {
    const { headlinesDossier, oddsDossier, videonews } = props;
    const __meta = useTranslation('meta').t;

    /** Ref to increment injected index **/
    const increment = useRef<number>(0);

    const { displayType } = displayConfig;

    const injections = useMemo(() => {
        const injectedItems: Injection[] = [];

        increment.current = 0;

        if (videonews && shouldInclude(platform, NewsListInsert.LATEST_VIDEO_ARTICLES)) {
            increment.current += 5;
            const metaLatestVideosTitle = __meta(`${platform}-homepage.latest-videos-title`);
            injectedItems[increment.current] = {
                element: (
                    <Experiment
                        type={RunningExperiments.VIDEOS_ON_HOME}
                        A={() => (
                            <NewsList
                                title={<h2>{metaLatestVideosTitle}</h2>}
                                items={videonews}
                                moreArticlesLink={__meta('url:videos')}
                                displayType={DisplayType.carrousel}
                                displayTypeSwitchOptions={[]}
                                isHighlighted={true}
                                showIconTabs={false}
                            />
                        )}
                    />
                ),
            };
        }

        if (oddsDossier && shouldInclude(platform, NewsListInsert.ODDS_DOSSIER)) {
            increment.current += 5;
            if (oddsDossier.tag) oddsDossier.tag.title = '';
            if (platform === PlatformID.VI && oddsDossier.matches) {
                const match = oddsDossier.matches[0];
                const bookmaker = oddsDossier?.items?.length ? oddsDossier.items[0]?.bookmaker : undefined;

                injectedItems[increment.current] = {
                    element: (
                        <MatchBlock
                            trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                            match={match}
                            bookmaker={bookmaker}
                        />
                    ),
                };
            } else {
                injectedItems[increment.current] = {
                    element: (
                        <OddsDossierIntro
                            trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                            {...oddsDossier}
                        />
                    ),
                };
            }
        }

        if (headlinesDossier && shouldInclude(platform, NewsListInsert.HEADLINES_DOSSIER)) {
            increment.current += 5;
            injectedItems[increment.current] = { element: <HeadlinesDossier {...headlinesDossier} /> };
        }

        if (shouldInclude(platform, NewsListInsert.VIDEO_PLAYER)) {
            increment.current += 5;
            if (platform === PlatformID.BR) {
                injectedItems[increment.current] = {
                    element: (
                        <Experiment
                            type={RunningExperiments.VIDEOS_ON_HOME}
                            B={() => (
                                <>
                                    <OnScrollVideoCarousel />
                                    <Ad placement={AdPlacement.HOME_AFTER_PLAYER} isOptional />
                                </>
                            )}
                        />
                    ),
                };
            } else {
                injectedItems[increment.current] = {
                    element: (
                        <>
                            <OnScrollVideoCarousel />
                            <Ad placement={AdPlacement.HOME_AFTER_PLAYER} isOptional />
                        </>
                    ),
                };
            }

            increment.current += 5;
            injectedItems[increment.current] = {
                element: <Ad placement={AdPlacement.HOME_AFTER_PLAYER_AFTER_ARTICLE_5} />,
            };
        }

        const placements: { fullWidth: boolean; placement: AdPlacement }[] = [];

        if (displayType === DisplayType.blocks) {
            placements[9] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_10 };
            placements[18] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_20 };
            placements[27] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_30 };
            placements[36] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_40 };
            placements[45] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_50 };
            placements[54] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_60 };
        } else {
            placements[10] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_10 };
            placements[20] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_20 };
            placements[30] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_30 };
            placements[40] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_40 };
            placements[50] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_50 };
            placements[60] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_60 };
        }

        placements.forEach((placement, index) => {
            injectedItems[index] = injectedItems[index]
                ? {
                      element: (
                          <>
                              <Ad placement={placement.placement} />
                              {injectedItems[index].element}
                          </>
                      ),
                      fullWidth: placement.fullWidth,
                  }
                : {
                      element: <Ad placement={placement.placement} />,
                      fullWidth: placement.fullWidth,
                  };
        });

        return injectedItems;
    }, [videonews, platform, oddsDossier, headlinesDossier, __meta, displayType]);

    return { injections };
};
